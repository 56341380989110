import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BfyProvider } from "./BfyContext";
import ReactGA from "react-ga4";
require('dotenv').config();
ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_MEASUREMENT_ID
  }
]);

ReactDOM.render(
  <BfyProvider>
    <App />
  </BfyProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
